import React from 'react';
import { Link } from 'react-router-dom';
import { WorksContents } from '../organisms/works/WorksContents';
import styled from 'styled-components';
import media from 'styled-media-query';
import work_1 from './img/pbd_art_001.jpg'
import work_2 from './img/pbd_art_002.jpg'
import work_3 from './img/pbd_art_003.jpg'
import work_4 from './img/pbd_art_004.jpg'
import work_5 from './img/pbd_art_005.jpg'
import work_6 from './img/pbd_art_006.jpg'


const works = [
  { background: work_1, },
  { background: work_2, },
  { background: work_3, },
  { background: work_4, },
  { background: work_5, },
  { background: work_6, },
]

export const Works = () => {

  // const [showImage, setShowImage] = useState(false);
  // const [worksIndex, setWorksIndex] = useState(0);
  // const [workType, setWorkType] = useState();

  return (
    <>
      <SContainer>
        <STitle>WORKS</STitle>
        <SWorks>
          {
            React.Children.toArray(works.map((g, i) => {
              return (
                <>
                  {/* <SWork  src={g.background} onClick={() => { setShowImage(true); setWorkType(g.workType) }}> */}
                  <SWork>
                    {/* <SWorkTitleContainer>
                      <SWorkTitle>{g.title}</SWorkTitle>
                    </SWorkTitleContainer>
                    <SCommentContainer>
                      <SComment>{g.comment}</SComment>
                    </SCommentContainer> */}
                    <SImg src={g.background} />
                    {/* <SMaskContainer>
                      <SMakingComment>
                        <LinkButton url={g.url}>VIEW MORE</LinkButton>
                      </SMakingComment>
                    </SMaskContainer> */}
                  </SWork >
                  {/* <ModalImage showImage={showImage} setShowImage={setShowImage} workType={workType} setWorkType={setWorkType}  worksIndex={worksIndex} setWorksIndex={setWorksIndex}></ModalImage> */}
                </>
              );
            }))
          }

        </SWorks>
      </SContainer>
    </>
  );
}

const SContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0 0 0;
  font-family: 'Yuji Boku', serif;
`
const STitle = styled.h2`
  text-align: center;
  letter-spacing: .1em;
`
const SWorks = styled.div`
  margin: 20px 0 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  justify-content: center;
`
const SWork = styled.div`
  margin: 0 20px 70px;
  position:		relative;
`
const SWorkTitleContainer = styled.div`
  height: 48px;
`
const SWorkTitle = styled.h3`
  letter-spacing: .1em;
  margin: 12px 0 0 12px;
  white-space: pre-wrap;
`
const SCommentContainer = styled.div`
  height: 60px;
`
const SComment = styled.div`
  font-size: 12px;
  letter-spacing: .1em;
  margin: 12px 0 0 12px;
`
const SImg = styled.img`
  width: 100%;
  transition: .2s;
  &:hover {
    cursor: pointer;
  }
`
const SMaskContainer = styled(Link)`
  width: 100%;
  aspect-ratio: 1/1;
  position: absolute;
  top: 168px;
  left: 0;
  background-color:	rgba(0,0,0,0.3);
  color: #782A7E;
  text-decoration: none;
  display: flex;
  align-items: center;
    

  ${media.greaterThan("large")`
    width: 100%;
    aspect-ratio: 1/1;
    position: absolute;
    top: 168px;
    left: 0;
    opacity: 0;
    background-color:	rgba(0,0,0,0.3);
    color: #fff;
    text-decoration: none;

    display: flex;
    align-items: flex-end;

    &:hover {
      opacity: .3;
    }
  `}
`
const SMakingComment = styled.div`
  width: 100%;
  height: 100%;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`
const SMaskingPDF = styled.div`
width: 100%;
text-align: center;
`