import React from 'react';
import styled from "styled-components";
import AboutImage from "../pages/img/about.jpg"

export const About = () => {

  return (
    <>
      <SContainer>
        <STitle>ABOUT</STitle>
        <SImgContainer>
          <SImg src={AboutImage} alt="AboutImage"></SImg>
        </SImgContainer>
        <SContent>
          <h2>PINCHBALL DEVILS</h2>
          <br />
          45、873、44の3人で構成された天国と地獄の板挟みアートFlock<br />
          <br />
          <h2>45</h2>
          173cm, 83kg<br />
          B:90, W:90, H:90<br />
          こわす, なくす, たすける<br />
          <br />
          <h2>873</h2>
          151cm,100kg<br />
          B:120, W:120, H:120<br />
          まるい, とどく, はじく<br />
          <br />
          <h2>44</h2>
          176cm, 76kg<br />
          B:60, W:60, H:60<br />
          のる, あやつる, みがく<br />
        </SContent>
      </SContainer>
    </>
  );
}

const SContainer = styled.div`
  width: 100%;
  min-height: 500px;
  margin: 0 auto;
  padding: 24px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const SContent = styled.div`
  margin: 60px auto;
  line-height: 24px;
  color: #782A7E;
  width: 90%;
  max-width: 600px;
  text-align: center;
`
const SImgContainer = styled.div`
  max-width: 1170px;
  margin: 0px auto;
  text-align: center;
`
const SImg = styled.img`
  width: 80%;
`
const STitle = styled.h2`
  font-family: 'Yuji Boku', serif;
  font-size: 24px;
  width: 200px;
  color: #782A7E;
  position: relative;
  z-index: 9999;
  text-align: center;
  margin: 0 0 30px 0;
`