import React from 'react';
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { DefaultLayout } from "../templates/DefaultLayout"
import { ScrollTop } from "./ScrollTop"
// import { HeaderOnly } from "../templates/HeaderOnly"

import { Home } from "../pages/Home";
import { About } from "../pages/About";
import { Works } from "../pages/Works";


export const Router = () => {
  return (
    <BrowserRouter>
      <ScrollTop />
      <Switch>
        <Route exact path="/">
          <DefaultLayout>
            <Home></Home>
          </DefaultLayout>
        </Route>
        <Route exact path="/about">
          <DefaultLayout>
            <About></About>
          </DefaultLayout>
        </Route>
        <Route exact path="/works">
          <DefaultLayout>
            <Works></Works>
          </DefaultLayout>
        </Route>

      </Switch>
    </BrowserRouter>
  );
}
