import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from "styled-media-query";
import { IconFotter } from '../icon/IconFotter';

export const Fotter = () => {
  return (
    <>
      <SFotter>
        <SLinks>
          <SNav>
            <SUl>
              <SLink to="/" style={{ color: "#782A7E" }}>TOP</SLink>
              <SLink to="/about" style={{ color: "#782A7E" }}>ABOUT</SLink>
              <SLink to="/works" style={{ color: "#782A7E" }}>WORKS</SLink>
            </SUl>
          </SNav>
        </SLinks>
        <IconFotter></IconFotter>
        <SP>&copy; 2023 PINCHBALL DEVILS</SP>
      </SFotter>
    </>
  );
}

const SLink = styled(Link)`
  letter-spacing: .08em;
  color: #782A7E;
  padding: 0 0 0 36px;
  text-decoration: none;
  &:hover{
    cursor: pointer;
  }
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    padding: 20px 10px;
  `}
`
const SUl = styled.ul`
`
const SNav = styled.nav`
  margin: 0 20px 0 0;
  position:relative;
`
const SLinks = styled.div`
  margin: 60px 0 12px 0;
  display: flex;
  align-items: center;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    display: block;
  `}
`

const SP = styled.p`
  font-size: 10px;
  color: #782A7E;
  margin: 10px 0 30px 0;
`

const SFotter = styled.footer`
  height: 132px;
  width: 100%;
  background: rbga(0,0,0,0);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 9999;
`