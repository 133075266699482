import React from 'react';
import styled from "styled-components";
import { LinkButton } from '../../atoms/button/LinkButton';
import media from "styled-media-query";
import TopImage from "../../pages/img/top_getpic.jpg"

export const HomeHeader = () => {

  return (
    <>
      <SContainer>
        <SImgContainer>
          <SImg src={TopImage} alt="TopImage"></SImg>
        </SImgContainer>
      </SContainer>
    </>
  );
}


// HEADER
const SContainer = styled.div`
  margin: 0px auto;
  text-align: center;
  min-height: 1000px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${media.lessThan("small")`
  min-height: 700px;
  `}
`
const SImgContainer = styled.div`
  margin: 60px auto;
  max-width: 1170px;
  text-align: center;
`
const SImg = styled.img`
  width: 60%;
  ${media.lessThan("small")`
  width: 90%
  `}
`
const Stitle = styled.h1`
  font-family: 'Yuji Boku', serif;
  font-weight: 400;
  font-size: 34px;
  letter-spacing: .08em;
  color: #fff;
`
const SComment = styled.p`
  margin: 0 auto;
  width: fit-content;
  max-width: 500px;
  font-size: 10px;
  letter-spacing: .05em;
  line-height: 24px;
`

const SContent = styled.p`
  margin: 30px auto 0 auto;
  font-size: 13px;
  letter-spacing: .05em;
  line-height: 24px;
  color: #fff;
  width: 90%;
  max-width: 500px;
`